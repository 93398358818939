import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { H3Icon, H3List } from '@h3/thinking-ui';
import { WorkPlatformService } from '@/sevices/index';
import { namespace } from 'vuex-class';
const workPlatformModule = namespace('workPlatform');
let ComponentName = class ComponentName extends Vue {
    constructor() {
        super(...arguments);
        this.onlineAppList = [];
        this.page = 1;
        this.finished = false;
        this.loading = false;
        this.flag = false;
    }
    /**
     * 滚动加载
     */
    async nextPage() {
        // 控件问题，如果数据没撑满一页的时候会发起两次请求，所以拦截一下
        if (this.flag) {
            return;
        }
        this.flag = true;
        setTimeout(() => {
            this.flag = false;
        }, 100);
        // end
        this.loading = true;
        const data = await this.getAppList();
        // console.log('data', data);
        this.page++;
        this.onlineAppList.push(...data.data);
        this.setOlineApps(this.onlineAppList);
        this.$emit('triggerAllAppLength', this.onlineAppList.length);
        this.finished = this.onlineAppList.length >= (data.total || 0);
        this.loading = false;
    }
    /**
     * 获取应用列表
     */
    async getAppList() {
        return new Promise((resolve, reject) => {
            const params = {
                keywork: '',
                page: this.page,
                size: 40,
            };
            WorkPlatformService.getAppList(params).then((res) => {
                if (res.success) {
                    resolve(res);
                }
            }).catch((e) => {
                reject(e);
            });
        });
    }
    goToSearch() {
        this.$router.push({
            name: 'm-work-platform-home-search-app',
        });
    }
};
__decorate([
    workPlatformModule.Action('setOlineApps')
], ComponentName.prototype, "setOlineApps", void 0);
__decorate([
    workPlatformModule.State('isSetOrg')
], ComponentName.prototype, "isSetOrg", void 0);
__decorate([
    workPlatformModule.State('recentApps')
], ComponentName.prototype, "recentApps", void 0);
ComponentName = __decorate([
    Component({
        name: 'app-list',
        components: {
            H3Icon,
            H3List,
            AppItem: () => import('./app-item'),
        },
    })
], ComponentName);
export default ComponentName;
